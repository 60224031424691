.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);
}
.h-wrapper{
    color: white;
    z-index: 99;
}
.h-menu{
    gap:2rem;
}
.h-menu>*:hover{
    cursor: pointer;
}
.menu-icon{
    display: none;
}
@media (max-width:765px) {
    .menu-icon{
        display: block;
    }
    .h-menu{
        z-index: 99;
        color: var(--black);
        position: absolute;
        top: 3rem;
        right: 4rem;
        background: white;
        font-weight: 500;
        gap: 2rem;
        padding: 3rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        flex-direction: column;
        border-radius: 10px;
        align-items: flex-start;
        transition: all 300ms ease-in;
    }
}