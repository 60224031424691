.App{
  position: relative;
  background-color: white;
  overflow-x: clip;
}
.App:nth-child(1){
  background: var(--black);
  position: relative;
}
.white-gradient{
  position: absolute;
  width:20rem;
  height: 20rem;
  background: rgba(255, 255, 255, 0.537);
  filter: blur(100px);
}