.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    z-index: 1;
}
.hero-container{
    justify-content: space-around;
    align-items: flex-end;
}
.image-container {
    width:30rem;
    height: 30rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0 ;
    border: 8px solid rgba(255, 255, 255, 0.12);
}
.image-container img{
    width: 100%;
    height: 100%;
}
.hero-left{
    gap: 3rem;
}
.hero-title{
    position: relative;
    z-index: 1;
}
.hero-title>h1{
    font-size: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}
.orange-circle{
    width: 4rem;
    position: absolute;
    border-radius: 999px;
    height: 4rem;
    background: var(--orange-gradient);
    right: 29%;
    top: -10%;
    z-index: -1;
}
.search-bar >input{
    border: none;
    outline: none;
}
.search-bar{
    background-color: white;
    border-radius: 5px;
    border: 3px solid rgba(120,120,120,0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    
}
.stats{
    width: 100%;
    justify-content: space-between;
    gap: 3rem;
}
.stat>:nth-child(1){
    font-size: 2rem;
}
.stat>:nth-child(1) > :last-child{
    color: orange;
}
@media (max-width:600px) {
    .hero-container{
        margin-top: 2rem;
    }
    .hero-title>h1{
        line-height: 3rem;
        font-size: 2.5rem;
    }
    .image-container{
        height: 25rem;
        width: 95%;
    }
    .stats{
        justify-content: center;
        gap:1.5rem;
    }
    .hero-right{
        width: 100%;
    }
    .stat:nth-child(1){
        font-size: 1.2rem;
    }
    .stat:nth-child(2){
        font-size: 0.8rem;
    }
}